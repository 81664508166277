import CenterLayout from "@components/centerlayout"
import Parallax, { ImgTitle, ImgCopyright } from "@components/parallax"
import styled from "@emotion/styled"
import DividerLine from "@components/DividerLine"
import Link from "@ui/Link/Link"
import Text from "@ui/Text/Text"
import { graphql } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { rem } from "polished"
import React from "react"

import { DividerIllustration } from "../components/DividerIllustration"
import Layout from "../components/layout"
import SEO from "../components/seo"
import afdbLogo from "../images/afdb_logo.svg"
import nepadLogo from "../images/nepad_logo.svg"
import undpLogo from "../images/undp_logo.svg"
import unecaLogo from "../images/uneca_logo.svg"
import { space, SpaceProps } from "styled-system"

const Logos = styled.div<SpaceProps>`
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  gap: 2rem;

  ${space};

  @media (min-width: 37em) {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, auto);
  }

  @media (min-width: 55em) {
    grid-template-rows: unset;
    grid-template-columns: repeat(4, 1fr);
  }
`

const Contact = styled.address`
  font-style: normal;
`

const ImgLink = styled.a`
  text-decoration: none;
  display: flex;
  margin-bottom: 1rem;
  transition: 0.2s ease-in-out;
  min-height: 6rem;
  align-items: flex-end;

  :hover,
  :focus {
    outline: 0;
    opacity: 0.65;
  }
`

interface Props {
  data: {
    humanAndPlanet: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData
      }
    }
  }
}

const ContactPage = ({ data }: Props) => (
  <Layout>
    <SEO title="Contact" />
    <DividerIllustration loading="eager" />
    <CenterLayout my="5xl">
      <Text size="md" as="h1" fontWeight="bold" mb="lg">
        Contact &amp; Support
      </Text>
      <Text size="md" as="h1" fontWeight="bold" mb="4xl">
        For questions or support don’t hesitate to contact us on{" "}
        <Link href="mailto:ipguideafrica@gmail.com">
          ipguideafrica@gmail.com
        </Link>
      </Text>
      <DividerLine />
      <Logos my="3xl">
        <Contact>
          <ImgLink
            href="https://www.africa.undp.org"
            rel="noopener"
            target="_blank"
          >
            <img src={undpLogo} height="82" alt="UNDP" />
          </ImgLink>
          <Text size="md" fontWeight="bold">
            UNDP
          </Text>
          <Text size="md" fontWeight="bold">
            United Nations Development Programme
          </Text>
        </Contact>
        <Contact>
          <ImgLink
            href="https://www.afdb.org/en"
            rel="noopener"
            target="_blank"
          >
            <img src={afdbLogo} height="68" alt="AFDB" />
          </ImgLink>
          <Text size="md" fontWeight="bold" style={{ whiteSpace: "nowrap" }}>
            AfDB
          </Text>
          <Text size="md" fontWeight="bold">
            The African Development Bank
          </Text>
        </Contact>
        <Contact>
          <ImgLink href="https://www.uneca.org" rel="noopener" target="_blank">
            <img src={unecaLogo} height="40" alt="UNECA" />
          </ImgLink>
          <Text size="md" fontWeight="bold">
            UNECA
          </Text>
          <Text size="md" fontWeight="bold">
            United Nations Economic Comission for Africa
          </Text>
        </Contact>
        <Contact>
          <ImgLink href="https://www.nepad.org" rel="noopener" target="_blank">
            <img src={nepadLogo} height="52" alt="NAPTA" />
          </ImgLink>
          <Text size="md" fontWeight="bold">
            AUDA-NEPAD
          </Text>
          <Text size="md" fontWeight="bold">
            The African Union Development Agency
          </Text>
        </Contact>
      </Logos>

      <DividerLine />

      <Text size="md" fontWeight="bold" mt="3xl" mb="sm">
        Disclaimer
      </Text>
      <Text size="md">
        The views expressed in this publication are those of the author(s) and
        do not necessarily represent the views of the African Development Bank,
        the African Union Development Agency (AUDA-NEPAD), the United Nations
        Development Programme, the United Nations Economic Commission for
        Africa, the United Nations generally, or United Nations Member States.
      </Text>
    </CenterLayout>
    <Parallax
      scaleFactor={1.25}
      backgroundProps={{
        image: data.humanAndPlanet.childImageSharp.gatsbyImageData,
        alt: "Plans for humans and planet",
      }}
    >
      <CenterLayout py={rem("224px")}>
        <ImgTitle>Plans for humans and planet</ImgTitle>
      </CenterLayout>
      <ImgCopyright>
        Upgrade Drinking Water Supply in the Rabat – Casablanca Coastal Area,
        Morocco
      </ImgCopyright>
    </Parallax>
  </Layout>
)

export const pageQuery = graphql`
  {
    humanAndPlanet: file(relativePath: { eq: "human-and-planet.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          transformOptions: { fit: CONTAIN, cropFocus: ATTENTION }
          layout: FULL_WIDTH
        )
      }
    }
  }
`

export default ContactPage
